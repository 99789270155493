import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { settings } from './helper.js';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import Exhibitions from './components/pages/Exhibitions';
import Home from './components/pages/Home';
import Info from './components/Info';


let abortController = new AbortController();
const abort = () => abortController.abort();


function App() {
    const [blur, setBlur] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [cropping, setCropping] = useState<boolean>(false);

    const [exhibitions, setExhibitions] = useState<any>([]);

    const [imageKey, setImageKey] = useState<string>('');
    const [info, setInfo] = useState<any>(null);
    const [items, setItems] = useState<any>(null);

    const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const [loginDone, setLoginDone] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [loginSaving, setLoginSaving] = useState<boolean>(false);

    const [modalLogin, setModalLogin] = useState<boolean>(false);

    const os = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? 'apple' : '';

    const [password, setPassword] = useState<string>('');
    const passwordRef = useRef<HTMLInputElement>(null);

    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)breyer\s*=\s*([^;]*).*$)|^.*$/, "$1");
    const [token, setToken] = useState<string>(cookie !== '' ? JSON.parse(cookie).token : '');


    useEffect(() => {
        // console.log('init APP', items)

        // document.body.addEventListener('mousemove', (e) => {
        //     const ea = document.getElementById('background');
        //     if (ea) {
        //         ea.style.backgroundPositionX = (-e.clientX/100) + 'px';
        //         ea.style.backgroundPositionY = (-e.clientY/100) + 'px';
        //     }
        // });

        // document.addEventListener('scroll', (e: any) => {
        //     const ea = document.getElementById('background');
        //     if (ea) {
        //         // ea.style.backgroundPositionY = (window.scrollY / 10 + 200) + 'px';
        //         // ea.style.backgroundSize = 'auto ' + (100 + window.scrollY / 100) + '%';
        //         ea.style.top = (window.scrollY / 10 - 200) + 'px';
        //     }
        // });

        document.body.addEventListener('mousemove', handleCropMove(false), false);
        document.body.addEventListener('mouseup', handleCropEnd, false);
        document.body.addEventListener('touchend', handleCropEnd, false);
        document.body.addEventListener('touchmove', handleCropMove(true), false);
    }, []);


    const login = (e: any) => {
        e.preventDefault();

        setLoginSaving(true);

        abort();
        abortController = new AbortController();

        const options = {
            'body': JSON.stringify({ 'mode': 'login', 'password': password, }),
            'method': 'POST',
            'signal': abortController.signal,
        };

        fetch('https://' + settings.domain + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    if (data && data.status) {
                        setLoginSaving(false);
                        setLoginDone(true);
                        setTimeout(() => {
                            setLoginDone(false);
                            setModalLogin(false);
                            setBlur(false);
                        }, 1000);

                        setToken(data.token);

                        let date = new Date();
                        date.setTime(date.getTime() + (6 * 60 * 60 * 1000));
                        document.cookie = 'breyer=' + JSON.stringify({ 'token': data.token, }) + '; expires=' + date.toUTCString() + '; path=/; domain=; SameSite=Strict';
                    } else {
                        setLoginSaving(false);
                        setLoginError(true);
                        setTimeout(() => {
                            setLoginError(false);
                        }, 1000);
                    }
                }, 1000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    };

    const logout = () => {
        let date = new Date();
        date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
        document.cookie = 'breyer=; expires=' + date.toUTCString() + '; path=/; domain=; SameSite=Strict';

        setPassword('');
        setToken('');
    }


    const handleLogin = () => {
        setModalLogin(true);
        setTimeout(() => {
            setBlur(true);
        }, 0);
        setTimeout(() => {
            passwordRef.current!.focus();
        }, 500);
    }


    const handleCropEnd = () => {
        setCropping(false);

        const eb = document.getElementById('crop_active');
        if (eb) {
            eb.removeAttribute('data-m');
            eb.removeAttribute('id');
        }
    }

    const handleCropMove = (touch: boolean) => (e: any) => {
        let ecx;
        let ecy;

        if (touch) {
            ecx = e.touches[0].clientX;
            ecy = e.touches[0].clientY;
        } else {
            ecx = e.clientX;
            ecy = e.clientY;
        }

        const ea = document.getElementById('crop_active');
        if (ea && ea.hasAttribute('data-m')) {
            const eb = ea.parentNode!.querySelector('img');

            if (eb) {
                setChanged(true);

                const ra = ea.getBoundingClientRect();
                const rb = eb.getBoundingClientRect();

                switch (ea.getAttribute('data-m')) {
                    case ('mo'): {
                        ea.style.left = Math.min(rb.width - ra.width, Math.max(0, ra.left - rb.left + (ecx - +ea.getAttribute('data-x')!))) + 'px';
                        ea.style.top = Math.min(rb.height - ra.height, Math.max(0, ra.top - rb.top + (ecy - +ea.getAttribute('data-y')!))) + 'px';

                        break;
                    }

                    case ('ne'): {
                        const w = ra.width + ecx - +ea.getAttribute('data-x')!;
                        if (w >= 100 && w < rb.width - (ra.x - rb.x)) {
                            ea.style.width = w + 'px';
                        }

                        const h = ra.height + +ea.getAttribute('data-y')! - ecy;
                        const t = ra.top - rb.top + ecy - +ea.getAttribute('data-y')!;
                        if (h >= 100 && t >= 0) {
                            ea.style.height = h + 'px';
                            ea.style.top = t + 'px';
                        }

                        break;
                    }

                    case ('nw'): {
                        const w = ra.width + +ea.getAttribute('data-x')! - ecx;
                        const l = ra.left - rb.left + ecx - +ea.getAttribute('data-x')!;
                        if (w >= 100 && l >= 0) {
                            ea.style.width = w + 'px';
                            ea.style.left = l + 'px';
                        }

                        const h = ra.height + +ea.getAttribute('data-y')! - ecy;
                        const t = ra.top - rb.top + ecy - +ea.getAttribute('data-y')!;
                        if (h >= 100 && t >= 0) {
                            ea.style.height = h + 'px';
                            ea.style.top = t + 'px';
                        }

                        break;
                    }

                    case ('se'): {
                        const w = ra.width + ecx - +ea.getAttribute('data-x')!;
                        if (w >= 100 && w < rb.width - (ra.x - rb.x)) {
                            ea.style.width = w + 'px';
                        }

                        const h = ra.height + ecy - +ea.getAttribute('data-y')!;
                        if (h >= 100 && h < rb.height - (ra.y - rb.y)) {
                            ea.style.height = h + 'px';
                        }

                        break;
                    }

                    case ('sw'): {
                        const w = ra.width + +ea.getAttribute('data-x')! - ecx;
                        const l = ra.left - rb.left + ecx - +ea.getAttribute('data-x')!;
                        if (w >= 100 && l >= 0) {
                            ea.style.width = w + 'px';
                            ea.style.left = l + 'px';
                        }

                        const h = ra.height + ecy - +ea.getAttribute('data-y')!;
                        if (h >= 100 && h < rb.height - (ra.y - rb.y)) {
                            ea.style.height = h + 'px';
                        }

                        break;
                    }
                }

                ea.setAttribute('data-c', JSON.stringify({
                    'h': Math.round(100 / rb.height * ra.height),
                    'w': Math.round(100 / rb.width * ra.width),
                    'x': Math.round(100 / rb.width * (ra.x - rb.x)),
                    'y': Math.round(100 / rb.height * (ra.y - rb.y)),
                    'updated': true,
                }));
                ea.setAttribute('data-x', ecx);
                ea.setAttribute('data-y', ecy);
            }
        }
    }

    const handleCropStart = (mode: string, touch: boolean) => (e: any) => {
        setCropping(true);

        const ea = e.target.parentNode;
        ea.setAttribute('data-m', mode);
        ea.setAttribute('id', 'crop_active');

        if (touch) {
            ea.setAttribute('data-x', e.touches[0].clientX);
            ea.setAttribute('data-y', e.touches[0].clientY);
        } else {
            ea.setAttribute('data-x', e.clientX);
            ea.setAttribute('data-y', e.clientY);
        }
    }


    return (
        <>
            {
                loadingInfo || loadingPage
                    ? <div id="spinner" className="dark">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    : ''
            }


            <BrowserRouter>
                <Container
                    fluid={false}
                    className={token !== '' ? 'with_token' : ''}
                    id="app"
                    style={blur ? { 'filter': 'blur(3px) grayscale(1)', } : loadingInfo || loadingPage ? { 'display': 'none', } : {}}
                >
                    <div id="login">
                        {
                            token === ''
                                ? <Button onClick={handleLogin} size="sm" variant="link">
                                    <span>login</span>
                                </Button>
                                : <Button onClick={logout} size="sm" variant="link">
                                    <span>logout</span>
                                </Button>
                        }
                    </div>


                    <Info
                        changed={changed}
                        cropping={cropping}
                        handleCropStart={handleCropStart}
                        info={info}
                        os={os}
                        setBlur={setBlur}
                        setChanged={setChanged}
                        setImageKey={setImageKey}
                        setInfo={setInfo}
                        setLoadingInfo={setLoadingInfo}
                        token={token}
                    />


                    <Switch>
                        <Route path={'/exhibitions/'}>
                            <Exhibitions
                                exhibitions={exhibitions}
                                imageKey={imageKey}
                                info={info}
                                os={os}
                                setBlur={setBlur}
                                setExhibitions={setExhibitions}
                                setLoadingPage={setLoadingPage}
                                token={token}
                            />
                        </Route>
                        <Route>
                            <Home
                                changed={changed}
                                cropping={cropping}
                                handleCropStart={handleCropStart}
                                imageKey={imageKey}
                                info={info}
                                items={items}
                                os={os}
                                setBlur={setBlur}
                                setChanged={setChanged}
                                setItems={setItems}
                                setLoadingPage={setLoadingPage}
                                token={token}
                            />
                        </Route>
                    </Switch>
                </Container>
            </BrowserRouter>


            <Modal
                centered
                onHide={() => {
                    setModalLogin(false);
                    setBlur(false);
                }}
                show={modalLogin}
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={login}>
                        <Form.Group>
                            <Form.Control placeholder="password" ref={passwordRef} type="password" value={password} onChange={(e: any) => { setPassword(e.target.value); }} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={os}>
                    <Button
                        onClick={login}
                        size="sm"
                        variant="primary">
                        <span>Login</span>
                    </Button>
                    <Button
                        onClick={() => {
                            setModalLogin(false);
                            setBlur(false);
                        }}
                        size="sm"
                        variant="secondary"
                    >
                        <span>Cancel</span>
                    </Button>
                </Modal.Footer>

                {
                    loginSaving
                        ? <div id="modal_fullscreen">
                            <Spinner
                                animation="border"
                                aria-hidden="true"
                                as="span"
                                role="status"
                                variant="light"
                            />
                        </div>
                        : loginDone
                            ? <div id="modal_fullscreen">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            : loginError
                                ? <div id="modal_fullscreen">
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                                : ''
                }
            </Modal>
        </>
    );
}

export default App;
