import React, { useEffect, useState } from 'react';
// import './Home.css';

import { settings } from '../../helper.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowCircleLeft, faArrowCircleRight, faArrowDown, faArrowUp, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

// import ToggleButton from 'react-bootstrap/ToggleButton';

import ItemLarge from '../ItemLarge';
import ItemSmall from '../ItemSmall';
import Navigation from '../Navigation';


let abortController = new AbortController();
const abort = () => abortController.abort();


function Home(props: any) {
    const [categories, setCategories] = useState<any>([]);
    const [category, setCategory] = useState<any>([]);

    const [changed, setChanged] = useState<boolean>(props.changed);
    const [changeData, setChangeData] = useState<any>(null);

    const [imageDelete, setImageDelete] = useState<any>(null);

    const [itemDelete, setItemDelete] = useState<any>(null);
    const [itemDone, setItemDone] = useState<boolean>(false);
    const [itemSaving, setItemSaving] = useState<boolean>(false);

    const [item, setItem] = useState<any>(null);
    const [items, setItems] = useState<any>(props.items);

    const [loading, setLoading] = useState<boolean>(false);

    const [modal, setModal] = useState<string>('');
    const [modalNew, setModalNew] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');


    let history = useHistory();
    let location = useLocation();


    useEffect(() => {
        // console.log('init HOME', items);

        // window.scrollTo(0, 0);

        if (!items) {
            fetch('https://' + settings.domain + '/api.php',
                {
                    'body': JSON.stringify({ 'mode': 'items_get', }),
                    'method': 'POST',
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setTimeout(() => {
                        setItems(data);
                        props.setItems(data);
                        props.setLoadingPage(false);

                        let path = location.pathname.split('/');
                        if (path.length > 3 && path[1] === 'item') {
                            for (let i = 0, li = data.length; i < li; ++i) {
                                if (data[i].id === path[2]) {
                                    setItem(i);
                                    break;
                                }
                            }
                        }
                    }, 2000);
                })
                .catch((e) => {
                    console.log('fetch failed');
                });
        }
    }, []);


    useEffect(() => {
        if (items) {
            let path = location.pathname.split('/');
            if (path.length > 2 && path[1] === 'item') {
                for (let i = 0, li = items.length; i < li; ++i) {
                    if (items[i].id === path[2]) {
                        setItem(i);
                        break;
                    }
                }
            }


            let c = [];

            for (let i = 0, li = items.length; i < li; ++i) {
                if (c.indexOf(items[i].year) < 0 && items[i].year !== '') {
                    c.push(items[i].year);
                }
            }
            c = c.sort((a, b) => {
                return a.localeCompare(b);
            });

            setCategories(c);
        }
    }, [items]);


    useEffect(() => {
        let path = location.pathname.split('/');

        if (path.length > 2) {
            switch (path[1]) {
                case ('category'):
                    props.setBlur(false);
                    setCategory(path.slice(2));
                    setItem(null);
                    setSearch('');

                    break;

                case ('item'):
                    for (let i = 0, li = items.length; i < li; ++i) {
                        if (items[i].id === path[2]) {
                            props.setBlur(false);
                            setCategory([]);
                            setItem(i);
                            setSearch('');

                            // window.scrollTo(0, 0);

                            break;
                        }
                    }
                    break;

                case ('search'):
                    props.setBlur(false);
                    setCategory([]);
                    setItem(null);
                    setSearch(path[2]);

                    break;
            }
        } else {
            props.setBlur(false);
            setCategory([]);
            setItem(null);
            setSearch('');

            // window.scrollTo(0, 0);
        }
    }, [location]);


    // useEffect(() => {
    //     if (modal === 'item') {
    //         setTimeout(() => {
    //             console.log('addEventListener')
    //             document.body.addEventListener('mousemove', handleCropMove(false), false);
    //             document.body.addEventListener('mouseup', handleCropEnd, false);
    //             document.body.addEventListener('touchend', handleCropEnd, false);
    //             document.body.addEventListener('touchmove', handleCropMove(true), false);
    //         }, 500);
    //     } else {
    //         // console.log('removeEventListener')
    //         // console.log(document.body.removeEventListener('mousemove', handleCropMove(false), false));
    //         // document.body.removeEventListener('mouseup', handleCropEnd, false);
    //         // document.body.removeEventListener('touchend', handleCropEnd, false);
    //         // document.body.removeEventListener('touchmove', handleCropMove(true), false);
    //     }
    // }, [modal]);


    const itemSet = () => {
        abort();
        abortController = new AbortController();

        setItemSaving(true);

        let index = 0;
        if (!modalNew) {
            for (let i = 0, li = items.length; i < li; ++i) {
                if (items[i].id === changeData.id) {
                    index = i;
                    break;
                }
            }
        }


        let options = {
            'body': new FormData(),
            'method': 'POST',
        };


        let crop: any = [];
        const ea = document.getElementsByClassName('crop_crop');
        if (ea) {
            for (let i = 0, li = ea.length; i < li; ++i) {
                crop.push(JSON.parse(ea[i].getAttribute('data-c')!));
            }
        }


        for (let ka in changeData) {
            switch (ka) {
                case ('crop'):
                    options.body.append('crop', JSON.stringify(crop));
                    break;

                case ('images'):
                    options.body.append('images', JSON.stringify(changeData['images']));
                    break;

                default:
                    options.body.append(ka, changeData[ka]);
                    break;
            }
        }


        options.body.append('index', '' + index);
        options.body.append('mode', 'item_set');
        options.body.append('new', modalNew ? '1' : '0');
        options.body.append('token', props.token);


        fetch('https://' + settings.domain + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    setItemSaving(false);
                    setItemDone(true);

                    setTimeout(() => {
                        setItemDone(false);
                        setChanged(false);
                        props.setChanged(false);
                        props.setBlur(false);
                        setModal('');
                    }, 1000);

                    changeData.abc = new Date().getTime();

                    if (modalNew) {
                        props.setItems((data: any) => {
                            return [{ ...changeData, 'crop': crop, }, ...data];
                        });
                        setItems((data: any) => {
                            return [{ ...changeData, 'crop': crop, }, ...data];
                        });
                    } else {
                        props.setItems((data: any) => {
                            data.splice(index, 1, { ...changeData, 'crop': crop, });
                            return [...data];
                        });
                        setItems((data: any) => {
                            data.splice(index, 1, { ...changeData, 'crop': crop, });
                            return [...data];
                        });
                    }
                }, 1000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    };

    const itemImageAdd = (e: any) => {
        const files = e.target.files;
        const reader = new FileReader();

        reader.onload = (e: any) => {
            setChangeData((data: any) => {
                return {
                    ...data,
                    'crop': [...data.crop, {
                        'h': 100,
                        'w': 100,
                        'x': 0,
                        'y': 0,
                    }],
                    'images': [...data.images, e.target.result],
                }
            });
            setChanged(true);
        }

        reader.readAsDataURL(files[0]);
    }

    const itemImageDelete = () => {
        setChangeData((data: any) => {
            let cr = [...data.crop];
            cr.splice(imageDelete, 1);

            let im = [...data.images];
            im.splice(imageDelete, 1);

            return {
                ...data,
                'crop': cr,
                'images': im,
            }
        });
        setChanged(true);
    }


    const itemLoad = (id: any) => {
        setLoading(true);

        for (let i = 0, li = items.length; i < li; ++i) {
            if (items[i].id === id) {
                setChangeData({ ...items[i], });
                setModalNew(false);
                props.setBlur(true);
                setModal('item');

                let img = document.createElement('img');

                img.onload = () => {
                    setLoading(false);
                };

                img.onerror = () => {
                    console.log('error')
                };

                img.src = items[i].images[0].indexOf('.jpeg') < 0 ? items[i].images[0] : 'https://' + settings.domain + '/items/og_' + items[i].images[0] + '?' + items[i].abc;

                break;
            }
        }
    }


    const addItem = (e: any) => {
        const files = e.target.files;
        const reader = new FileReader();

        reader.onload = (e: any) => {
            setChangeData({
                'abc': '',
                'crop': [{
                    'h': 100,
                    'w': 100,
                    'x': 0,
                    'y': 0,
                }],
                'dimensions': '',
                'id': '' + new Date().getTime(),
                'images': [e.target.result],
                'price': '',
                'status': false,
                'text': '',
                'title': '',
                'year': '',
            });
            setModal('item');
        }

        reader.readAsDataURL(files[0]);
    }


    const deleteItem = () => {
        for (let i = 0, li = items.length; i < li; ++i) {
            if (items[i].id === itemDelete) {
                const options = {
                    'body': JSON.stringify({
                        'index': i,
                        'mode': 'item_delete',
                        'token': props.token,
                    }),
                    'method': 'POST',
                };

                fetch('https://' + settings.domain + '/api.php', options)
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((e) => {
                        console.log('fetch failed');
                    });

                let data = [...items];
                data.splice(i, 1);
                props.setItems(data);
                setItems(data);
            }
        }
    }


    const moveItem = (direction: number, id: number) => () => {
        const items_: any = items
            .filter((va: any) => {
                return !category.length || category.indexOf(slug(va.year)) > -1
            })
            .filter((va: any) => {
                return search === '' || (va.dimensions + va.price + va.text + va.title + va.year).match(new RegExp(search, 'i'))
            });

        let a = 0;
        let b = 0;

        for (let i = 0, li = items_.length; i < li; ++i) {
            if (items_[i].id === id) {
                for (let j = 0, lj = items.length; j < lj; ++j) {
                    if (items[j].id === items_[i].id) {
                        a = j;
                    }

                    if (items[j].id === items_[i + direction].id) {
                        b = j;
                    }
                }
            }
        }


        let p = [...items];
        p.splice(b, 0, p.splice(a, 1)[0]);
        props.setItems(p);
        setItems(p);

        fetch('https://' + settings.domain + '/api.php',
            {
                'body': JSON.stringify({
                    'a': a,
                    'b': b,
                    'mode': 'item_move_left_right',
                    'token': props.token,
                }),
                'method': 'POST',
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    }


    const slug = (data: string) => {
        return data.replace(/[^\u00C0-\u017FA-z0-9]+/g, '-').toLowerCase().trim();
    }


    const ItemButtons = (data: any) => {
        return (
            <div className="item_buttons">
                <ButtonGroup size="sm">
                    <Button
                        onClick={() => {
                            itemLoad(data.data.id);
                        }}
                        variant="dark"
                    >
                        <span>Change</span>
                    </Button>

                    <Button
                        onClick={() => {
                            setItemDelete(data.data.id);
                            props.setBlur(true);
                        }}
                        variant="dark"
                    >
                        <span>Delete</span>
                    </Button>


                    {
                        item === null && data.index > 0
                            ? <Button
                                onClick={moveItem(-1, data.data.id)}
                                variant="dark"
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </Button>
                            : ''
                    }

                    {
                        item === null && data.index < items.length - 1
                            ? <Button
                                onClick={moveItem(1, data.data.id)}
                                variant="dark"
                            >
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Button>
                            : ''
                    }
                </ButtonGroup>
            </div>
        );
    };


    const handleCategory = (key: any, e: any) => {
        let path = location.pathname.split('/');

        if (path.length > 2 && path[1] === 'category') {
            path.push(key);
            history.push(Array.from(new Set(path)).join('/'));
        } else {
            history.push('/category/' + key);
        }
    }

    const handleCategoryRemove = (data: string) => () => {
        let path = location.pathname.split('/');

        if (path.filter((va: string) => { return va !== ''; }).length === 2) {
            history.push('/');
        } else {
            history.push(path.filter((va: string) => {
                return va !== data;
            }).join('/'));
        }
    }


    const handleDownUp = (direction: number, index: number) => () => {
        let cr = [...changeData.crop];
        [cr[index], cr[index + direction]] = [cr[index + direction], cr[index]];

        let im = [...changeData.images];
        [im[index], im[index + direction]] = [im[index + direction], im[index]];

        setChangeData({ ...changeData, 'crop': cr, 'images': im, });
        setChanged(true);
    }


    const HelmetFrame = () => {
        return (
            <Helmet>
                <title>{'Home - ' + props.info.title}</title>

                <meta name="description" content={props.info.text} />

                <meta property="og:description" content={props.info.text} />
                <meta property="og:image" content={'https://' + settings.domain + '/logo512.png?' + props.info.key} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content={'Home - ' + props.info.title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={document.location.href} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={props.info.text} />
                <meta name="twitter:image" content={'https://' + settings.domain + '/logo512.png?' + props.info.key} />
                <meta name="twitter:title" content={'Home - ' + props.info.title} />
                <meta name="twitter:url" content={document.location.href} />
            </Helmet>
        );
    }


    if (items) {
        const items_ = items
            .filter((va: any) => {
                return !category.length || category.indexOf(slug(va.year)) > -1
            })
            .filter((va: any) => {
                return search === '' || (va.dimensions + va.price + va.text + va.title + va.year).match(new RegExp(search, 'i'))
            });


        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div
                    className={props.cropping ? 'cropping' : ''}
                    id="home"
                >

                    <Navigation
                        categories={categories}
                        handleCategory={handleCategory}
                        imageKey={props.imageKey}
                        scope=""
                        search={search}
                        setSearch={setSearch}
                    />


                    {
                        // categories
                        //     ? <Dropdown onSelect={handleCategory}>
                        //         <Dropdown.Toggle
                        //             variant="light"
                        //             id="categories_dropdown"
                        //         >
                        //             Categories
                        //         </Dropdown.Toggle>

                        //         <Dropdown.Menu>

                        //             {
                        //                 categories
                        //                     .map((va: string, ia: number) => {
                        //                         return (
                        //                             <Dropdown.Item
                        //                                 eventKey={slug(va)}
                        //                                 key={ia}
                        //                             >
                        //                                 {va}
                        //                             </Dropdown.Item>
                        //                         )
                        //                     })
                        //             }
                        //         </Dropdown.Menu>
                        //     </Dropdown>
                        //     : ''
                    }


                    {
                        category.length
                            ? <div id="filter">
                                {
                                    category
                                        .filter((va: string) => { return va !== '' })
                                        .map((va: string, ia: number) => {
                                            return (
                                                <Button
                                                    key={ia}
                                                    onClick={handleCategoryRemove(va)}
                                                    size="sm"
                                                    variant="secondary"
                                                >
                                                    {va + ' '}
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                </Button>
                                            )
                                        })
                                }
                            </div>
                            : ''
                    }


                    {
                        props.token !== '' && !item
                            ? <div style={{ 'margin': '2rem 0', 'textAlign': 'center', }}>
                                <Button
                                    className="add_image"
                                    onClick={() => {
                                        history.push('/');
                                        setModalNew(true);
                                    }}
                                    size="sm"
                                    variant="dark"
                                >
                                    <span>Add New Item</span>
                                    <input type="file" onChange={addItem} accept=".gif, .jpg, .jpeg, .png" />
                                </Button>
                            </div>
                            : ''
                    }


                    {
                        items_.length
                            ? item !== null
                                ? <div id="item">
                                    <ItemLarge
                                        key={item + '-' + items[item].abc}
                                        carousel={0}
                                        info={props.info}
                                        item={item}
                                        items={items}
                                        setBlur={props.setBlur}
                                        setCategory={undefined}
                                    />

                                    {
                                        props.token !== ''
                                            ? <ItemButtons
                                                data={items[item]}
                                                index={item}
                                            />
                                            : ''
                                    }
                                </div>
                                : <div>
                                    <Row lg={4} md={3} sm={2} xs={1}>
                                        {
                                            items_
                                                .map((va: any, ia: number) => {
                                                    return (
                                                        <Col key={ia}>
                                                            <div style={props.token !== '' ? { 'padding': '0 0 1rem 0', 'border': '1px #CCC solid', } : {}}>
                                                                <ItemSmall
                                                                    key={va.abc}
                                                                    {...va}
                                                                />

                                                                {
                                                                    props.token !== ''
                                                                        ? <ItemButtons
                                                                            data={va}
                                                                            index={ia}
                                                                        />
                                                                        : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                        }
                                    </Row>
                                </div>
                            : <div id="no_results_found">NO RESULTS FOUND</div>
                    }
                </div>


                <Modal
                    centered
                    className={props.cropping ? 'cropping' : ''}
                    id="item_modal"
                    onHide={() => {
                        if (!props.cropping) {
                            setChanged(false);
                            props.setChanged(false);
                            props.setBlur(false);
                            setModal('');
                        }
                    }}
                    show={modal === 'item'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                // modalNew
                                //     ? <span>Add New Item</span>
                                //     : <span>Change Item</span>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    {
                        changeData !== null
                            ? <Modal.Body>
                                {
                                    loading
                                        ? <div id="item_spinner">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        : <Card>
                                            <div id="crop_frame">
                                                {
                                                    changeData.images
                                                        .map((va: any, ia: number) => {
                                                            return (
                                                                <>
                                                                    <div key={ia}>
                                                                        <Image src={va.indexOf('.jpeg') < 0 ? va : 'https://' + settings.domain + '/items/og_' + va + '?' + changeData.abc} />

                                                                        <div className="crop_crop" style={{ 'top': changeData.crop[ia].y + '%', 'left': changeData.crop[ia].x + '%', 'width': changeData.crop[ia].w + '%', 'height': changeData.crop[ia].h + '%', }} data-c={JSON.stringify(changeData.crop[ia])}>
                                                                            <span className="crop_cut" onMouseDown={props.handleCropStart('mo', false)} onTouchStart={props.handleCropStart('mo', true)} />
                                                                            <span className="crop_ne" onMouseDown={props.handleCropStart('ne', false)} onTouchStart={props.handleCropStart('ne', true)} />
                                                                            <span className="crop_nw" onMouseDown={props.handleCropStart('nw', false)} onTouchStart={props.handleCropStart('nw', true)} />
                                                                            <span className="crop_se" onMouseDown={props.handleCropStart('se', false)} onTouchStart={props.handleCropStart('se', true)} />
                                                                            <span className="crop_sw" onMouseDown={props.handleCropStart('sw', false)} onTouchStart={props.handleCropStart('sw', true)} />
                                                                        </div>


                                                                    </div>

                                                                    <div style={{ 'margin': '0.5rem 0 0 0', 'textAlign': 'center', }}>
                                                                        <ButtonGroup size="sm">
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setImageDelete(ia);
                                                                                    props.setBlur(true);
                                                                                }}
                                                                                variant="dark"
                                                                            >
                                                                                <span>Delete Image</span>
                                                                            </Button>

                                                                            {
                                                                                ia > 0
                                                                                    ? <Button onClick={handleDownUp(-1, ia)} variant="dark">
                                                                                        <FontAwesomeIcon icon={faArrowUp} />
                                                                                    </Button>
                                                                                    : ''
                                                                            }

                                                                            {
                                                                                ia < changeData.images.length - 1
                                                                                    ? <Button onClick={handleDownUp(1, ia)} variant="dark">
                                                                                        <FontAwesomeIcon icon={faArrowDown} />
                                                                                    </Button>
                                                                                    : ''
                                                                            }
                                                                        </ButtonGroup>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                }
                                            </div>

                                            <div style={{ 'margin': '2rem 0 0 0', 'textAlign': 'center', }}>
                                                <Button
                                                    className="add_image"
                                                    size="sm"
                                                    variant="dark"
                                                >
                                                    <span>Add Image</span>
                                                    <input type="file" onChange={itemImageAdd} accept=".gif, .jpg, .jpeg, .png" />
                                                </Button>
                                            </div>

                                            <Card.Body>
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>CATEGORY</Form.Label>
                                                        <Form.Control type="text" value={changeData.year ? changeData.year : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'year': e.target.value, } }) }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>TITLE</Form.Label>
                                                        <Form.Control type="text" value={changeData.title ? changeData.title : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'title': e.target.value, } }) }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>TEXT</Form.Label>
                                                        <Form.Control as="textarea" rows={3} value={changeData.text ? changeData.text : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'text': e.target.value, } }) }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Row>
                                                            <Col>
                                                                <Form.Label style={{ 'fontSize': '12px', }}>LABELS</Form.Label>
                                                                <Form.Control type="text" value={changeData.dimensions ? changeData.dimensions : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'dimensions': e.target.value, } }) }} />
                                                            </Col>
                                                            <Col>
                                                                <Form.Label style={{ 'fontSize': '12px', }}>&nbsp;</Form.Label>
                                                                <Form.Control type="text" value={changeData.price ? changeData.price : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'price': e.target.value, } }) }} />
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>


                                                    {/* <div style={{ 'textAlign': 'center', }}>
                                                        <div>Sold</div>
                                                        <ButtonGroup size="sm" toggle>
                                                            <ToggleButton
                                                                type="radio"
                                                                name="radio"
                                                                value="1"
                                                                variant="dark"
                                                                checked={changeData.status}
                                                                onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'status': true, } }) }}
                                                            >Yes</ToggleButton>
                                                            <ToggleButton
                                                                type="radio"
                                                                name="radio"
                                                                value="0"
                                                                variant="dark"
                                                                checked={!changeData.status}
                                                                onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'status': false, } }) }}
                                                            >No</ToggleButton>
                                                        </ButtonGroup>
                                                    </div> */}
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                }
                            </Modal.Body>
                            : ''
                    }

                    <Modal.Footer className={props.os}>
                        <Button
                            className="loading_done"
                            disabled={!changed && !props.changed && !modalNew}
                            onClick={() => {
                                itemSet();
                            }}
                            size="sm"
                            variant="primary"
                        >
                            {
                                modalNew
                                    ? <span>Save Item</span>
                                    : <span>Save Changes</span>
                            }
                        </Button>
                        <Button
                            onClick={() => {
                                setChanged(false);
                                props.setChanged(false);
                                props.setBlur(false);
                                setModal('');
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>

                    {
                        itemSaving
                            ? <div id="modal_fullscreen">
                                <Spinner
                                    animation="border"
                                    aria-hidden="true"
                                    as="span"
                                    role="status"
                                    variant="light"
                                />
                            </div>
                            : itemDone
                                ? <div id="modal_fullscreen">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                : ''
                    }
                </Modal>


                <Modal
                    centered
                    onHide={() => {
                        setImageDelete(null);
                        props.setBlur(false);
                    }}
                    show={imageDelete !== null}
                    size="sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Image?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className={props.os}>
                        <Button
                            onClick={() => {
                                itemImageDelete();
                                setImageDelete(null);
                                props.setBlur(false);
                            }}
                            size="sm"
                            variant="primary"
                        >
                            <span>Confirm</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setImageDelete(null);
                                props.setBlur(false);
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    centered
                    onHide={() => {
                        setItemDelete(null);
                        props.setBlur(false);
                    }}
                    show={itemDelete !== null}
                    size="sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Item?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className={props.os}>
                        <Button
                            onClick={() => {
                                deleteItem();
                                setItemDelete(null);
                                props.setBlur(false);
                            }}
                            size="sm"
                            variant="primary"
                        >
                            <span>Confirm</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setItemDelete(null);
                                props.setBlur(false);
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div id="spinner" className="dark">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </>
        );
    }
}

export default Home;
