import React, { useEffect, useState } from 'react';
// import './Exhibitions.css';
import { settings } from '../../helper.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Navigation from '../Navigation';


let abortController = new AbortController();
const abort = () => abortController.abort();


function Exhibitions(props: any) {
    const [changed, setChanged] = useState<boolean>(false);
    const [changeData, setChangeData] = useState<any>(null);

    const [exhibitionDelete, setExhibitionDelete] = useState<any>(null);
    const [exhibitionDone, setExhibitionDone] = useState<boolean>(false);
    const [exhibitionSaving, setExhibitionSaving] = useState<boolean>(false);

    const [exhibitions, setExhibitions] = useState<any>(props.exhibitions);

    const [modal, setModal] = useState<string>('');
    const [modalNew, setModalNew] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');

    let location = useLocation();


    useEffect(() => {
        // console.log('init EXHIBITIONS', exhibitions);

        window.scrollTo(0, 0);

        if (!exhibitions.length) {
            fetch('https://' + settings.domain + '/api.php',
                {
                    'body': JSON.stringify({ 'mode': 'exhibitions_get', }),
                    'method': 'POST',
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    props.setExhibitions(data);
                    setExhibitions(data);
                    props.setLoadingPage(false);
                })
                .catch((e) => {
                    console.log('fetch failed');
                });
        }
    }, []);

    useEffect(() => {
        let path = location.pathname.split('/');

        if (path.length > 3) {
            switch (path[2]) {
                case ('search'):
                    props.setBlur(false);
                    setSearch(path[3]);
                    break;
            }
        } else {
            props.setBlur(false);
            setSearch('');
        }

        // window.scrollTo(0, 0);
    }, [location]);


    const exhibitionSet = () => {
        abort();
        abortController = new AbortController();

        setExhibitionSaving(true);

        let index = 0;

        if (!modalNew) {
            for (let i = 0, li = exhibitions.length; i < li; ++i) {
                console.log(exhibitions[i].id, changeData.id)
                if (exhibitions[i].id === changeData.id) {
                    index = i;
                    console.log(index)

                    break;
                }
            }
        }

        const options = {
            'body': JSON.stringify({
                'data': changeData,
                'index': index,
                'mode': 'exhibition_set',
                'new': modalNew,
                'token': props.token,
            }),
            'method': 'POST',
            'signal': abortController.signal,
        };

        fetch('https://' + settings.domain + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    setExhibitionSaving(false);
                    setExhibitionDone(true);
                    setTimeout(() => {
                        setExhibitionDone(false);
                        setChanged(false);

                        props.setBlur(false);
                        setModal('');
                    }, 1000);

                    if (modalNew) {
                        props.setExhibitions((data: any) => {
                            return [changeData, ...data];
                        });
                        setExhibitions((data: any) => {
                            return [changeData, ...data];
                        });
                    } else {
                        props.setExhibitions((data: any) => {
                            data.splice(index, 1, changeData);
                            return [...data];
                        });
                        setExhibitions((data: any) => {
                            data.splice(index, 1, changeData);
                            return [...data];
                        });
                    }
                }, 1000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    };


    const deleteExhibition = () => {
        const options = {
            'body': JSON.stringify({
                'index': exhibitionDelete,
                'mode': 'exhibition_delete',
                'token': props.token,
            }),
            'method': 'POST',
        };

        fetch('https://' + settings.domain + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.log('fetch failed');
            });


        let data = [...exhibitions];
        data.splice(exhibitionDelete, 1);
        props.setExhibitions(data);
        setExhibitions(data);
    }


    const moveExhibition = (direction: number, id: number) => () => {
        const exhibitions_: any = exhibitions
            .filter((va: any) => {
                return search === '' || (va.from_date + ' ' + va.from_time + ' ' + va.text + ' ' + va.to_date + ' ' + va.to_time).match(new RegExp(search, 'i'))
            });

        let a = 0;
        let b = 0;

        for (let i = 0, li = exhibitions_.length; i < li; ++i) {
            console.log(exhibitions_[i]);
            console.log(i + direction);

            if (exhibitions_[i].id === id) {
                for (let j = 0, lj = exhibitions.length; j < lj; ++j) {
                    if (exhibitions[j].id === exhibitions_[i].id) {
                        a = j;
                    }

                    if (exhibitions[j].id === exhibitions_[i + direction].id) {
                        b = j;
                    }
                }
            }
        }


        let e = [...exhibitions];
        e.splice(b, 0, e.splice(a, 1)[0]);
        props.setExhibitions(e);
        setExhibitions(e);

        fetch('https://' + settings.domain + '/api.php',
            {
                'body': JSON.stringify({
                    'a': a,
                    'b': b,
                    'mode': 'exhibition_move_down_up',
                    'token': props.token,
                }),
                'method': 'POST',
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    }


    const HelmetFrame = () => {
        return (
            <Helmet>
                <title>{'Exhibitions - ' + props.info.title}</title>

                <meta name="description" content={props.info.text} />

                <meta property="og:description" content={props.info.text} />
                <meta property="og:image" content={'https://' + settings.domain + '/logo512.png?' + props.info.key} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content={'Exhibitions - ' + props.info.title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={document.location.href} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={props.info.text} />
                <meta name="twitter:image" content={'https://' + settings.domain + '/logo512.png?' + props.info.key} />
                <meta name="twitter:title" content={'Exhibitions - ' + props.info.title} />
                <meta name="twitter:url" content={document.location.href} />
            </Helmet>
        );
    }


    if (exhibitions.length) {
        const exhibitions_ = exhibitions
            .filter((va: any) => {
                return search === '' || (va.from_date + ' ' + va.from_time + ' ' + va.text + ' ' + va.to_date + ' ' + va.to_time).match(new RegExp(search, 'i'))
            });

        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div id="exhibitions">
                    <Navigation
                        imageKey={props.imageKey}
                        scope="exhibitions/"
                        search={search}
                        setSearch={setSearch}
                    />


                    {
                        props.token !== ''
                            ? <div style={{ 'margin': '0 0 2rem 0', 'textAlign': 'center', }}>
                                <Button
                                    onClick={() => {
                                        setModalNew(true);
                                        setModal('exhibition');
                                        setChangeData({
                                            'id': new Date().getTime(),
                                        });
                                        props.setBlur(true);
                                    }}
                                    size="sm"
                                    variant="dark"
                                >
                                    <span>Add New Exhibition</span>
                                </Button>
                            </div>
                            : ''
                    }


                    {
                        exhibitions_.length
                            ? <div id="exhibitions_frame">
                                {
                                    exhibitions_
                                        .map((va: any, ia: number) => {
                                            return (
                                                <Row key={ia}>
                                                    <Col sm={12}>
                                                        <Row style={{ 'marginBottom': '1rem', }}>
                                                            <Col sm={12}>
                                                                {
                                                                    va.from_date && va.to_date
                                                                        ? va.from_date + ' - ' + va.to_date
                                                                        : va.from_date
                                                                            ? va.from_date
                                                                            : ''

                                                                }
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={9} style={{ 'whiteSpace': 'pre-line', }}>

                                                                {
                                                                    va.text
                                                                        ? <p>{va.text}</p>
                                                                        : ''
                                                                }

                                                                {
                                                                    va.link
                                                                        ? <p style={{ 'margin': '1rem 0 0 0', }}>
                                                                            <a href={va.link.slice(0, 4) === 'http' ? va.link : 'https://' + va.link}>{va.link}</a>
                                                                        </p>
                                                                        : ''
                                                                }

                                                            </Col>

                                                            {
                                                                props.token !== ''
                                                                    ? <Col sm={12} style={{ 'margin': '1rem 0 0 0', 'textAlign': 'center', }}>
                                                                        <ButtonGroup size="sm">
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setChangeData(va);
                                                                                    setModal('exhibition');
                                                                                    setModalNew(false);
                                                                                    props.setBlur(true);
                                                                                }}
                                                                                variant="dark"
                                                                            >
                                                                                <span>Change</span>
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setExhibitionDelete(ia);
                                                                                    props.setBlur(true);
                                                                                }}
                                                                                variant="dark"
                                                                            >
                                                                                <span>Delete</span>
                                                                            </Button>
                                                                        </ButtonGroup>

                                                                        <ButtonGroup size="sm">
                                                                            {
                                                                                ia > 0
                                                                                    ? <Button
                                                                                        onClick={moveExhibition(-1, va.id)}
                                                                                        variant="dark"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faArrowCircleUp} />
                                                                                    </Button>
                                                                                    : ''
                                                                            }
                                                                            {
                                                                                ia < exhibitions.length - 1
                                                                                    ? <Button
                                                                                        onClick={moveExhibition(1, va.id)}
                                                                                        variant="dark"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faArrowCircleDown} />
                                                                                    </Button>
                                                                                    : ''
                                                                            }
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                    : ''
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                }
                            </div>
                            : <div id="no_results_found">NO RESULTS FOUND</div>
                    }
                </div >


                <Modal
                    centered
                    id="exhibition_modal"
                    onHide={() => {
                        setChanged(false);
                        props.setBlur(false);
                        setModal('');
                    }}
                    show={modal === 'exhibition'}
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>
                            {
                                modalNew
                                    ? <span>Add New Exhibition</span>
                                    : <span>Change Exhibition</span>
                            }
                        </Modal.Title> */}
                    </Modal.Header>
                    {
                        changeData !== null
                            ? <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col sm={7}>
                                                        <Form.Label>From Date</Form.Label>
                                                        <Form.Control placeholder="From Date" size="sm" type="date" value={changeData.from_date} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'from_date': e.target.value, }; }); }} />
                                                    </Col>

                                                    {/* <Col sm={5} className="time">
                                                        <Form.Label>From Time</Form.Label>
                                                        <Form.Control placeholder="From Time" size="sm" type="time" value={changeData.from_time} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'from_time': e.target.value, }; }); }} />
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="to_date">
                                                <Row>
                                                    <Col sm={7}>
                                                        <Form.Label>To Date</Form.Label>
                                                        <Form.Control placeholder="To Date" size="sm" type="date" value={changeData.to_date} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'to_date': e.target.value, }; }); }} />
                                                    </Col>
                                                    {/* 
                                                    <Col sm={5} className="time">
                                                        <Form.Label>To Time</Form.Label>
                                                        <Form.Control placeholder="To Time" size="sm" type="time" value={changeData.to_time} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'to_time': e.target.value, }; }); }} />
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Label>Link</Form.Label>
                                                <Form.Control placeholder="" size="sm" type="text" value={changeData.link} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'link': e.target.value, }; }); }} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Label>Text</Form.Label>
                                                <Form.Control as="textarea" rows={4} value={changeData.text} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'text': e.target.value, }; }); }} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            : ''
                    }

                    <Modal.Footer className={props.os}>
                        <Button
                            className="loading_done"
                            disabled={!changed && !modalNew}
                            onClick={() => {
                                exhibitionSet();
                            }}
                            size="sm"
                            variant="primary"
                        >
                            {
                                modalNew
                                    ? <span>Add Exhibition</span>
                                    : <span>Save Changes</span>
                            }
                        </Button>
                        <Button
                            onClick={() => {
                                setChanged(false);
                                props.setBlur(false);
                                setModal('');
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>

                    {
                        exhibitionSaving
                            ? <div id="modal_fullscreen">
                                <Spinner
                                    animation="border"
                                    aria-hidden="true"
                                    as="span"
                                    role="status"
                                    variant="light"
                                />
                            </div>
                            : exhibitionDone
                                ? <div id="modal_fullscreen">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                : ''
                    }
                </Modal>


                <Modal
                    centered
                    onHide={() => {
                        setExhibitionDelete(null);
                        props.setBlur(false);
                    }}
                    show={exhibitionDelete !== null}
                    size="sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Exhibition?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className={props.os}>
                        <Button
                            onClick={() => {
                                deleteExhibition();
                                setExhibitionDelete(null);
                                props.setBlur(false);
                            }}
                            variant="primary"
                        >
                            <span>Delete</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setExhibitionDelete(null);
                                props.setBlur(false);
                            }}
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div id="spinner" className="dark">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </>
        );
    }
}

export default Exhibitions;