import React, { useState } from 'react';
// import './ItemSmall.css';
import { settings } from '../helper.js'

import { NavLink } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';


function ItemSmall(props: any) {
    const [loading, setLoading] = useState<boolean>(true);


    const slug = (data: string) => {
        return data.replace(/[^\u00C0-\u017FA-z0-9]+/g, '-').toLowerCase().trim();
    }


    return (
        <NavLink className="items" to={'/item/' + props.id + '/' + slug(props.title)}>
            <Card>
                {
                    loading
                        ? <div className="item_image_spinner">
                            <Spinner animation="grow" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : ''
                }

                <Card.Img
                    loading="lazy"
                    onLoad={() => { setTimeout(() => { setLoading(false); }, 100); }}
                    src={props.images[0].indexOf('.jpeg') < 0 ? props.images[0] : 'https://' + settings.domain + '/items/' + props.images[0] + '?' + props.abc}
                    variant="top"
                />

                {
                    props.title && props.title !== ''
                        ? <div>
                            <h5>
                                {props.title}
                            </h5>
                        </div>
                        : ''
                }

                {/* <Card.Body>
                    <ul className="item_facts">
                        {
                            // props.year && props.year !== ''
                            //     ? <li className="item_facts_year">{props.year}</li>
                            //     : ''
                        }

                        {
                            // props.title && props.title !== ''
                            //     ? <li>
                            //         <h4>
                            //             {props.title}
                            //         </h4>
                            //     </li>
                            //     : ''
                        }

                        {
                            // props.text && props.text !== ''
                            //     ? <li><p>{props.text}</p></li>
                            //     : ''
                        }

                        <li>
                            {
                                // props.dimensions && props.dimensions !== ''
                                //     ? <span>{props.dimensions}</span>
                                //     : ''
                            }

                            {
                                // props.price && props.price !== ''
                                //     ? <span>{props.price}</span>
                                //     : ''
                            }

                            {
                                // props.status
                                //     ? <span>SOLD</span>
                                //     : ''
                            }
                        </li>
                    </ul>
                </Card.Body> */}
            </Card>
        </NavLink>
    );
}

export default ItemSmall;
